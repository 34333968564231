import React from 'react';
import ProjectsListing from './ProjectsListing';
import {getRandBackground} from '../../utils/BackgroundColors';

class Projects extends React.Component{

	constructor(props) {
        super(props);
        this.state = { backgroundColor: props.backgroundColor, projectImage: '', isHover: false, isClick: false  }

        this.handleHover = this.handleHover.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleHover(projectImage){
    	//Si hay un hover, hay que cambiar el color de fondo e insertar la imagen del proyecto...
    	this.setState({
    		backgroundImage: projectImage,
    		isHover:true,
    		backgroundColor: getRandBackground( this.state.backgroundColor )
    	});
    }
    handleLeave(){
    	this.setState({
    		isHover: false,
    		backgroundColor: this.props.backgroundColor
    	})
    }

    

    handleClick( projectImage ){
        
        localStorage.setItem('clickedProject', projectImage);

        this.setState({
            isClick: true
        })
    }

    render(){
    	var posy = this.props.posy;
    	var backgroundColor = this.state.backgroundColor;

    	//var backgroundImage = this.state.backgroundImage;
        var backgroundImage = {
            backgroundImage : 'url('+this.state.backgroundImage+')',
        }
    	var isHover = this.state.isHover;

		if( posy < 0) posy = 0;
		
		var styleContainer = {
	        transform : 'translate3d(0px, '+posy+'px, 0px)',
	    }

	    var styleImage = {
	        opacity : isHover ? 1 : 0,
	    }

        var styleClick = this.state.isClick ? " image-zoom" : "";

        var styleScroll = {
            opacity : isHover && this.props.isActive ? 1 : 0,
        }

		return(
			<section style={styleContainer} className={'projects '+ backgroundColor + styleClick } >
				
				<div className="container">

					<div className="content col-12 col-md-8 mx-auto">

						<ProjectsListing category={this.props.category} isActive={this.props.isActive} onClick={this.handleClick} onHover={this.handleHover} onLeave={this.handleLeave} />

						<div className="project-image-hover full-screen" style={ styleImage }>
							<div className="project-background" style={backgroundImage}></div> 
						</div>

                        <div className="scroll-prompt" style={styleScroll}>
                            <span>Scroll</span>
                        </div>

					</div>

				</div>

			</section>
		);
    }
}


	

	


export default Projects;