import React from 'react';

function Credits( props ){

	return(
		<ul className="px-0">
			<li className="data-item">
				{props.date}
				<span className="metadata">Fecha</span>
			</li>
			<li className="data-item">
				{props.categories}
			</li>
			<li className="data-item">
				{props.title}
				<span className="metadata">Proyecto</span>
			</li>
			{props.credits}
		</ul>
	);
}

export default Credits;