import React from 'react';
import {Helmet} from "react-helmet";

import CoverProject from './CoverProject';
import Menu from "../menu/Menu";

import TemplateOne from "./templates/TemplateOne"
import TemplateTwo from "./templates/TemplateTwo"
import TemplateThree from "./templates/TemplateThree"

import FooterProjectListing from "../footer-project-listing/FooterProjectListing";

class Project extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			img: '',
			title: '',
			hasLoaded: false
		};

		this.page = null;
		this.categories_html = null;
	}

	componentDidMount() {
        window.onwheel = null;
    }

    componentWillMount(){

    	const projectId = typeof this.props.match.params.id !== 'undefined' ? this.props.match.params.id : 0;
    	
    	//Obtener los datos de este proyecto

    	global.client.getItem("proyectos",
            projectId,
            
            {
                fields: ['*.*', 'categorias.categoria.nombre', 'creditos.credito.*','sort']
            }

        ).then(data => {
            
            this.page = data.data;
            console.log(this);

            this.categories_html = this.page.categorias.map( (item, key) => 
				<span>{item.categoria.nombre}</span>
			);

            this.setState({ hasLoaded: true });
            localStorage.removeItem('clickedProject')
            
        }).catch(
            error => console.error(error) 
        );

    }

	/*componentDidMount() {
    	const { id }=this.props.match.params;

    	getProject( id )
    		.then( res ) => {
    			//Get Directus Info
    		}

    	this.setState({
    		img: 'https://miro.medium.com/max/4599/1*L0yDI-iTpkAbKzSBy6OSyw.jpeg',
    		title: 'lorem Ipsum',
    	});
	}*/

	render() {

		let clickedProject = localStorage.getItem('clickedProject');
		let hasClickedProject = clickedProject !== null ? true : false;

		let overlay_html;

		if( hasClickedProject ){
			overlay_html = 
			<div className={ "loader " + (this.state.hasLoaded ? "done" : "") } >
				<CoverProject  
					source={ clickedProject }
					title=""
				/>

				<div className="loading">
                    <span></span>
                </div>
			</div>
			
		}else{
			overlay_html =
				<div className={ "loader black " + (this.state.hasLoaded ? "done" : "") }>
	                

	                <Menu isActive={true} />

	                <div className="loading">
	                    <span></span>
	                </div>
	        
	            </div>
			;

		}

		
		

		return (
			<div className="project-wrapper">
				{this.state.hasLoaded &&
					<div>
						
						<Helmet>
		                    <title>{this.page.titulo_para_sharing}</title>
                            <meta name="description" content={this.page.descripcion_para_sharing}/>

                            <meta property="og:title" content={this.page.titulo_para_sharing} />
                            <meta property="og:description" content={this.page.descripcion_para_sharing} />
                            <meta property="og:image" content={this.page.imagen_para_sharing.data.full_url} />
		                </Helmet>

						<Menu isActive={true} />

						<CoverProject  
							source={ this.page.imagen_de_portada.data.full_url}
							title={ this.page.nombre}
						/>

						{this.page.plantilla === "1" &&
							<TemplateOne data={this.page} />
						}
						{this.page.plantilla === "2" &&
							<TemplateTwo data={this.page} />
						}
						{this.page.plantilla === "3" &&
							<TemplateThree data={this.page} />
						}

						<div className="row">
							<div className="col-12 col-md-10 px-md-0 mx-auto footer-container mt-0">
								<FooterProjectListing type="normal_projects" project_offset={this.page.sort} />
							</div>
						</div>
						
						<div className="categories">{ this.categories_html }</div>
						

					</div>
				}
				{overlay_html}

				
				
			</div>
		);

	}
}

export default Project;

