import React from 'react';
import {Helmet} from "react-helmet";
import Menu from "../../menu/Menu";
import _ from 'lodash';

class TeamContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
            breakPoints: [1000, 1000, 1000],
            currentProfile: 0,
            posy: -1000,
            hasLoaded: false
        }

        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.onWheel = this.onWheel.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);

        this.onWheel = _.debounce(this.onWheel, 6);
        this.updatePosy = _.debounce(this.updatePosy, 2);

        this.page = null;
        this.team = null; 

        this.team_images_html = null; 
        this.team_descriptions_html = null; 
    }

    onWheel(event) {
        
        event.persist();

        this.savedEvent = event;
        this.handleScroll( this.savedEvent.deltaY, 1, true);

    }

    onTouchStart(event) {
        //console.log(event.changedTouches[0].screenY);
        this.ts = event.changedTouches[0].screenY;
    }

    onTouchMove(event) {
        
        var te = event.changedTouches[0].screenY;
        var distance = this.ts - te;

        this.handleScroll( distance * -1, 0.5, false); 
    }

    handleResize(event){
        this.setState({
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight
        });

        this.setLimits();
        this.checkForBreakpoints();
    }

    handleScroll(direction, modifier, isMouseWheel) {
        
        var scrollDelta = direction;
        var step = 120 * modifier;

        //Si es scroll de MAC, el paso es de un 1%
        /*if( Math.abs(direction) < 10 )
            step*= 0.0002;*/
        if( isMouseWheel ){
            step*= direction * .009;
            step = Math.abs(step);
        }else{
            step*= direction * .0014;
            step = Math.abs(step);
        }

        

        var posy = this.state.posy;

        //arriba
        if (scrollDelta < 0 && this.state.posy <= 0 ) {
            posy += step;
        } else if (scrollDelta > 0 && this.state.posy >= this.state.limity) {
            posy -= step;
        }

        this.updatePosy(posy);


    }

    updatePosy( posy ){
        this.setState({posy: posy});
        this.checkForBreakpoints();
    }

    //Verificar si es necesario cambiar de imagen
    checkForBreakpoints(){
        
        if( this.state.posy >= this.state.breakPoints[ this.state.currentProfile ] && this.state.currentProfile < this.team.meta.result_count -1 ){
            this.setState({ currentProfile: this.state.currentProfile + 1 });
        }else if( this.state.posy <= this.state.breakPoints[ this.state.currentProfile -1 ] && this.state.currentProfile > 0 ){
            this.setState({ currentProfile: this.state.currentProfile - 1 });
        }
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        
        window.onwheel = function(){ return false; }
    }

    setLimits(){
        var teamContent = document.getElementById("team-content");

        var profileNodes = teamContent.childNodes;
        console.log(profileNodes);
        //establecer los puntos de quiebre iniciales para cada nodo hijo
        var breakPoints = [];
        //altura de todo el contenedor
        var breakPointStep = teamContent.clientHeight ;

        var suma = 0;

        for (var i = 0; i < profileNodes.length; i++) {
            
            breakPointStep = (breakPointStep - profileNodes[i].clientHeight - 600);
            
            var breakPoint = breakPointStep * -1;

            breakPoints.push(breakPoint + suma );
            suma = suma - 200;

            console.log(breakPoint);
        
        }

        this.setState({
            posy: (teamContent.clientHeight * 0.95) * -1, //Mantener abajo un 52% con respecto al tamaño de la columna,
            limity: (teamContent.clientHeight * 0.95) * -1,
            breakPoints: breakPoints
        });
    }

    componentWillMount() {
        
        global.client.getItems("pagina_de_team", {
            fields: ['*', 'imagen_para_sharing.data'],
            single: true
        }).then(response => {
            
            this.page = response.data;
            this.setState({ hasLoaded: true });

            global.client.getItems("team", {
                fields: ['descripcion', 'imagen.data'],
                meta: ['result_count'],
            }).then(response => {
                this.team = response;

                this.team_images_html = response.data.map( (item, key) => 
                    <img className={ key == 0 ? 'active' : ''} key={item.id} data-key={item.key}  src={item.imagen.data.full_url} alt='alt' />
                );

                this.team_descriptions_html = response.data.map( (item, key) => 
                    <div key={item.id} className="team-fragment" dangerouslySetInnerHTML={{__html: '<p>'+item.descripcion+'</p>' }}></div>
                );

                this.setState({ hasLoaded: true });
                this.setLimits();

            }).catch(
                error => console.error(error) 
            );

        }).catch(
            error => console.error(error) 
        );
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        var style = {
            bottom: this.state.posy+'px',
        }

       return(
            <div className="team page-wrapper" >
                
                {this.state.hasLoaded &&
                    <div>
                        <Helmet>
                            <title>{this.page.titulo_para_sharing}</title>
                            <meta name="description" content={this.page.descripcion_para_sharing}/>

                            <meta property="og:title" content={this.page.titulo_para_sharing} />
                            <meta property="og:description" content={this.page.descripcion_para_sharing} />
                            <meta property="og:image" content={this.page.imagen_para_sharing.data.full_url} />
                        </Helmet>
                        <Menu isActive={true} />
                        <div className="team-container" onWheel={ this.onWheel } onTouchStart={this.onTouchStart} onTouchMove={this.onTouchMove}>
                            <div className="container">
                                <div className="offset-2 offset-md-4 col-9 col-md-7 scrollable-column">
                                    
                                    <div className={"image" + " active-"+this.state.currentProfile }>
                                        { this.team_images_html }
                                    </div>

                                    <div id="team-content" style={style} className="content">
                                        { this.team_descriptions_html }
                                        
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className={ "loader white " + (this.state.hasLoaded ? "done" : "") }>
                    <Helmet>
                        <title>Wal</title>
                    </Helmet>

                    <div className="loading">
                        <span></span>
                    </div>
            
                </div>

            </div>
        );
    }

}

export default TeamContainer;