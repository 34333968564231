
export function stringStartsWith(str, match){
	
	//https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/String/startsWith
	if (!String.prototype.startsWith) {
	  	String.prototype.startsWith = function(stringBuscada, posicion) {
	    	posicion = posicion || 0;
	    	return this.indexOf(stringBuscada, posicion) === posicion;
	  	};
	}

	return str.startsWith(match);
}