import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

import AboutContainer from "./about-container/AboutContainer";

function About( props ) {

	const isFiltered = typeof props.match.params.id !== 'undefined' ? true : false;
	const projectId = typeof props.match.params.id !== 'undefined' ? props.match.params.id : 0;

	return (
		<AboutContainer filtered={isFiltered} projectId={projectId} />
	);
}

export default About;




