import React from 'react';

import _ from 'lodash';

import {Helmet} from "react-helmet";

import Menu from "../../menu/Menu";
import AboutProject from "../AboutProject";
import LinkItem from "../LinkItem";

class AboutContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasLoaded: false
        }
    }

    handleHover( event ){
        alert('aa')
    }

    componentWillMount(){
        if (!this.props.filtered){
            global.client.getItems("pagina_de_about", {
                fields: ['*.*']
            }).then(response => { 

                this.page = response.data[0];

                global.client.getItems("textos_de_about", {
                    fields: ['*.*']
                }).then(data => {    

                    let about_text = data.data;

                    this.about_text_html = about_text.map( (item, key) => 
                        <LinkItem item={item} />
                    );

                    this.setState({
                        hasLoaded: true
                    });

                }).catch(
                    error => console.error(error) 
                );

            }).catch(
                error => console.error(error) 
            );
        }
    }

    render() {
        return (
            <div className="about-wrapper">
                {this.props.filtered ? (
                    <AboutProject projectId={this.props.projectId} />
                ) : (
                    <div>
                        {this.state.hasLoaded &&
                            <div className="">
                                <Helmet>
                                    <title>{this.page.titulo_para_sharing}</title>
                                    <meta name="description" content={this.page.texto_para_sharing}/>
                                    <meta property="og:image" content={this.page.imagen_para_sharing.data.full_url} />
                                </Helmet>

                                <Menu isActive={true} />

                                <div className="container">
                                    <div className="row">
                                        <div className="col-12 about-description">
                                            {this.about_text_html}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }
                        <div className={ "loader black " + (this.state.hasLoaded ? "done" : "") }>
                            <Helmet>
                                <title>Wal</title>
                            </Helmet>

                            <div className="loading">
                                <span></span>
                            </div>
                    
                        </div>
                    </div>
                )}
            </div>
        );

    }
}

export default AboutContainer;




