import React from 'react';
import ReactDOM from 'react-dom';
import { /*Hash*/BrowserRouter as Router } from "react-router-dom";

import * as serviceWorker from './serviceWorker';
import Container from './components/Container';

const routing = (
  	<Router basename="/">
    	<Container />
  	</Router>
);



ReactDOM.render( routing, document.getElementById('root') )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
