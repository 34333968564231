import React from 'react';
import { NavLink, Link } from "react-router-dom";

import { stringStartsWith } from '../../utils/StartsWith';

class Menu extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            isOpened: false,
            hasLoaded: false,
        }

        this.handleClick = this.handleClick.bind(this);
    }

    componentWillMount() {
        
        global.client.getItems("pagina_de_contacto", {
            fields: ['instagram'],
            single: true,
            
        }).then(response => {
            
            this.instagram = response.data.instagram;
            this.setState({ hasLoaded: true });

        }).catch(
            error => console.error(error) 
        );
    }

    handleClick(){
    	
    	var willOpen = this.state.isOpened ? false : true;

    	this.setState({
    		isOpened: willOpen,
    	})
    }

    checkWorkActive(match, location){
    	return ( stringStartsWith(location.pathname, '/work') || stringStartsWith(location.pathname, '/project') );

    }

    render() {
        
        var isActive = this.props.isActive; 
        var isOpened = this.state.isOpened; 

        var activeClass = isActive ? "active " : "";
        var openedClass = isOpened ? "opened " : "";


		return(
			<header className={ 'menu '+ activeClass + openedClass } >
				<div className="menu-segment logo">
					<Link to="/">
						<img src="/sprites/logo.svg" alt="logo test"></img>
					</Link>
				</div>

				<div className="menu-segment instagram">
					{this.state.hasLoaded &&
						<a href={this.instagram} target="blank">
							Instagram
						</a>
					}
				</div>

				<div className="menu-segment navigation">
					<nav className="main-nav">
						<ul>
							<li><NavLink activeClassName="active" to="/about">About</NavLink></li>
							<li><NavLink activeClassName="active" to="/team">Team</NavLink></li>
							<li><NavLink activeClassName="active" isActive={this.checkWorkActive } to="/work">Work</NavLink></li>

							<li><NavLink activeClassName="active" to="/contact"><span>+</span></NavLink></li>
						</ul>
					</nav>
					<span className="menu-prompt" onClick={this.handleClick}>
						<span>.</span>
						<span>.</span>
						<span>.</span>
					</span>
				</div>
			</header>
		);
    }

}

export default Menu;