import React from 'react';
import TeamContainer from "./team-container/TeamContainer";

function Team( ) {
    
    return (
        <TeamContainer />
    );
        
}

export default Team;
