import React from 'react';
import {Helmet} from "react-helmet";
import Menu from "../../menu/Menu";
import { Link } from "react-router-dom";


class ContactContainer extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            hasLoaded: false,
            hasChanged: false,
            messageSent: false,
            error: "",
            name: "",
            email: "",
            message: "",
            challenge: "",
        }

        this.page = null;
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleMessageChange = this.handleMessageChange.bind(this);
        this.handleChallengeChange = this.handleChallengeChange.bind(this);
    }

    handleClick(){
        this.setState({ hasChanged: true });
    }

    handleSubmit( event ){
        
        event.preventDefault();
        
        let data = {
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
            challenge: this.state.challenge
        }

        let self = this;

        //Cambiar la URL en producción
        fetch('https://wal.yoco.ws/helpers/Contact.helper.php', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then( function( response ){
            
            return response.json();
        
        }).then( function( result ){
            
            if( result.ok ){

                self.setState({ messageSent: true });

            }else{
                
                throw new Error( result.msg );

            }

        }).catch( function( responseMsg ){
            
            self.setState({ error: responseMsg.toString() });
        
        });

    }


    componentDidMount() {
        window.onwheel = function(){ return false; }
    }

    handleNameChange(event){
        this.setState({name: event.target.value});
    }
    handleEmailChange(event){
        this.setState({email: event.target.value});
    }
    handleMessageChange(event){
        this.setState({message: event.target.value});
    }
    handleChallengeChange(event){
        this.setState({challenge: event.target.value});
    }

    componentWillMount() {
        
        global.client.getItems("pagina_de_contacto", {
            fields: ['*', 'imagen.data', 'imagen_para_sharing.data'],
            single: true,
            
        }).then(response => {
            
            this.page = response.data;
            this.setState({ hasLoaded: true });
            

        }).catch(
            error => console.error(error) 
        );
    }

    render() {
        
        return(
            <div  className={"contact page-wrapper has-fullscreen " + (this.props.isFormActive ? 'write-us' : '') } >
                

                {this.state.hasLoaded &&
                    <div>
                        <Helmet>
                            <title>{this.page.titulo_para_sharing}</title>
                            <meta name="description" content={this.page.descripcion_para_sharing}/>

                            <meta property="og:title" content={this.page.titulo_para_sharing} />
                            <meta property="og:description" content={this.page.descripcion_para_sharing} />
                            <meta property="og:image" content={this.page.imagen_para_sharing.data.full_url} />
                        </Helmet>
                        <Menu isActive={true} />

                        {this.props.isFormActive ? (
                    
                            <div>

                                <div className="contact-container">

                                    <div className="container">
                                        <div className="col-1 offset-10 mb-5 mb-md-5">
                                            <span class="close-form"><Link to="/contact">X</Link></span>
                                        </div>
                                        <div className="col-12 ">
                                            {this.state.messageSent ? (
                                                
                                                <div className="success-message col-12 col-md-7">
                                                    <h1>Thank you, your message has been sent and we will be contacting you back shortly.</h1>
                                                </div>

                                            ) : (

                                                <form onSubmit={this.handleSubmit} className={"form "+ (this.state.hasChanged ? 'changed' : '') }>
                                                
                                                    <div  className="form-group inline">
                                                        <label for="name">Name</label>
                                                        <div className="position-relative">
                                                            <input onChange={this.handleNameChange} type="text" name="name" className="h-100" id="name"  required />
                                                            <span className="cursor-prompt"></span>
                                                        </div>
                                                        
                                                    </div>

                                                    <div onClick={this.handleClick} className="form-group inline">
                                                        <label  for="medium">Email</label>
                                                        <input onChange={ this.handleEmailChange } onClick={this.handleClick} type="text" name="medium" id="medium" required />
                                                    </div>

                                                    <div onClick={this.handleClick} className="form-group column">
                                                        <label for="message">Let's create this</label>
                                                        <textarea onChange={ this.handleMessageChange } type="text" name="message" id="message"  rows="2" required></textarea>
                                                    </div>

                                                    <div onClick={this.handleClick} className="form-group challenge column">
                                                        <label className="small" for="challenge">Let’s say you are not a robot</label>
                                                        <input onChange={ this.handleChallengeChange } type="text" name="challenge" placeholder="What day of the week comes after tomorrow?" required />
                                                    </div>

                                                    <div className="form-group">
                                                        <input type="submit"  value="Send" />
                                                    </div>

                                                    {this.state.error !== "" ? (
                                                        <div className="error-message">
                                                            <h1>{this.state.error}</h1>
                                                        </div>
                                                    ) : (
                                                        <div></div>
                                                    )}

                                                </form>  

                                            )}
                                              
                                          
                                        </div>

                                    </div>

                                </div>

                            </div>
                            
                        ) : (

                            <div>

                                <div className="contact-container">

                                    <div className="container">
                                        
                                        <div className="col-12 col-md-10 mx-auto">
                                            
                                            <div className="contact-prompt col-md-10">
                                                <p>{this.page.text_1} <Link to="/contact/write-us">{this.page.text_2}</Link></p>
                                                <p>{this.page.text_3} <a href="https://calendly.com" target="_blank">{this.page.text_4}</a></p>
                                            </div>

                                            <div className="row mx-auto contact-data">
                                                <div className="mt-1 mt-md-5 col-12 col-md-4">

                                                    <a className="d-block" href={"mailto:"+this.page.email}>{this.page.email}</a>
                                                    <a className="d-block" href={"tel:"+this.page.telefono}>{this.page.telefono}</a>

                                                    <div className="social-networks">
                                                        <a href={this.page.instagram} target="_blank"><img src="/sprites/instagram.svg" /></a>
                                                        <a href={this.page.behance} target="_blank"><img src="/sprites/behance.svg" /></a>
                                                        <a href={this.page.whatsapp} target="_blank"><img src="/sprites/whatsapp.svg" /></a>
                                                    </div>

                                                </div>

                                                <div className="mt-4 mt-md-0 col-md-7 ml-auto ">
                                                    <img className="img-fluid" src={this.page.imagen.data.full_url} />
                                                </div>
                                            </div>

                                            

                                        </div>

                                    </div>

                                    <div className="background-image d-none">
                                        <div className="container">
                                            <div className="col-7 offset-4">
                                                <img src="/img/contacto.png" />
                                            </div>
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                            
                        )}

                    </div>
                    
                }

                <div className={ "loader black " + (this.state.hasLoaded ? "done" : "") }>
                    <Helmet>
                        <title>Wal</title>
                    </Helmet>

                    <Menu isActive={true} />

                    <div className="loading">
                        <span></span>
                    </div>
            
                </div>

            </div>
        );
    }

}

export default ContactContainer;