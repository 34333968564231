import React from 'react';

import Share from '../../share/Share'
import Credits from '../Credits'
import Media from './Media'

function TemplateOne( props ){

	let categories_html = props.data.categorias.map( (item, key) => 
		<span>{key > 0 ? ',' : ''}{item.categoria.nombre}</span>
	);

	let credits_html = props.data.creditos.map( (item, key) => 
		<li className="data-item">
			 {item.credito.persona}
			<span className="metadata">{item.credito.rol}</span>
		</li>
	);


	return(
		<div className="container project-body">
			<div className="row mb-md-4" >
				<div className="col-12 col-md-6 mb-4 d-flex align-items-between flex-column">
					<div className="w-100 mb-4 base-text">
						{ props.data.descripcion }
					</div>
					<figure className="w-100 px-0 mt-auto mb-0 mx-0">
						<Media media={ props.data.media_1 } />
					</figure>
				</div>
				<figure className="col-12 col-md-6 mb-4 mx-0">
					<Media media={ props.data.media_2 } />
				</figure>
			</div>
			<div className="row">
				<figure className="col-12 mx-0">
					<Media media={ props.data.media_3 } />
				</figure>
			</div>
			<div className="row">
				<div className="col-12 col-md-6 ml-auto">
					<Credits credits={credits_html} categories={categories_html} title={props.data.nombre} date={props.data.fecha} />
				</div>
			</div>
			<div className="row">
				<div className="col-12 col-md-4 project-sharing-container">
					<Share />
				</div>
			</div>
		</div>
	);
}

export default TemplateOne;