import React from 'react';
import ContactContainer from "./contact-container/ContactContainer";

function Contact( props ) {
    
    const isFormActive = typeof props.match.params.active !== 'undefined' ? true : false;
    return (
        <ContactContainer isFormActive={isFormActive} />
    );
        
}

export default Contact;
