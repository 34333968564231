import React from 'react';
import WorkContainer from "./work-container/WorkContainer";

function Work( props ) {
    
    const isFiltered = typeof props.match.params.id !== 'undefined' ? props.match.params.id : 0;

    return (
        <WorkContainer filtered={isFiltered} />
    );        
}

export default Work;
