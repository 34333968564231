import React from 'react';

import Share from '../../share/Share'
import Credits from '../Credits'
import Media from './Media'

function TemplateThree( props ){

	let categories_html = props.data.categorias.map( (item, key) => 
		<span>{key > 0 ? ', ' : ''}{item.categoria.nombre}</span>
	);

	let credits_html = props.data.creditos.map( (item, key) => 
		<li className="data-item">
			{item.credito.persona}
			<span className="metadata">{key !== 0 ? ',' : ''} {item.credito.rol}</span>
		</li>
	);

	return(
		<div className="container-fluid project-body">
			<div className="row" >
				<figure className="col-12 col-md-7 mb-4 mx-0 px-0">
					<Media media={ props.data.media_1 } />
				</figure>
				<div className="col-12 col-md-5 col-lg-4 mb-4 base-text offset-md-1">
					<div class="row">
						<div class="col-12 base-text">
							Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.
						</div>
					</div>
					<div class="row">
						<div className="col-12 mt-4">
							<Credits credits={credits_html} categories={categories_html} title={props.data.nombre} date={props.data.fecha} />
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<figure className="col-10 mb-4 mx-auto px-0">
					<Media media={ props.data.media_2 } />
				</figure>
			</div>
			<div className="row align-items-end">
				<figure className="col-12 col-md-7 mb-4 mx-0 px-0 pr-md-4">
					<Media media={ props.data.media_3 } />
				</figure>
				<figure className="col-12 col-md-5 mb-4 mx-0 px-0 pl-md-4">
					<Media media={ props.data.media_4 } />
				</figure>
			</div>
			<div className="row align-items-end">
				<figure className="col-12 col-md-5 mb-4 mx-0 px-0 pr-md-4">
					<Media media={ props.data.media_5 } />
				</figure>
				<figure className="col-12 col-md-7 mb-4 mx-0 px-0 pl-md-4">
					<Media media={ props.data.media_6 } />
				</figure>
			</div>
			<div className="row">
				<div className="col-12 col-md-4 project-sharing-container">
					<Share />
				</div>
			</div>
		</div>
	);
}

export default TemplateThree;