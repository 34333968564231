import React from 'react';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

import parse from 'html-react-parser';

import Menu from "../menu/Menu";
import Share from "../share/Share";
import FooterProjectListing from "../footer-project-listing/FooterProjectListing";

class AboutProject extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			title: '',
			hasLoaded: false
		};
	}

	componentDidMount() {
		document.body.scrollTop = 0;


    }

    componentWillMount(){
    	const projectId = this.props.projectId;

    	global.client.getItem("proyectos_internos", projectId, {
            fields: ['*.*']
        }).then(response => {  
        	this.page = response.data;
        	this.setState({ hasLoaded: true });
		}).catch(
            error => console.error(error) 
        );
    }

	render() {
		
		return (
			<div>
			{this.state.hasLoaded &&
				<div>
					
					<Helmet>
                        <meta property="og:title" content={this.page.titulo_para_sharing} />
                        <meta property="og:description" content={this.page.descripcion_para_sharing} />
                        <meta property="og:image" content={this.page.imagen_para_sharing.data.full_url} />
                        
	                </Helmet>

					<Menu isActive={true} />

					<div className="container-fluid" >
					<div className="row color-header" style={{background:this.page.color}}>
						<div className="col-12 text-center mt-auto">
							<h1 className="title">{this.page.titulo}</h1>
						</div>
					</div>
					<div className="row">
						<div className="container about-project large-margin-top">
							<div className="row wal-logo-container">
								<figure className="col-12 col-md-8">
									<img src={this.page.media_1.data.full_url} alt="" className="img-fluid" />
								</figure>
								<div className="col-4 col-md-3 mx-auto my-4 wal-black-logo">
									<img src="/img/VV.png" alt="VV" className="img-fluid dark-logo" />
								</div>
							</div>
							<div className="row position-relative layer-one">
								<div className="col-12 col-md-5 offset-md-2 top-layer-text base-text">
									{ parse(this.page.descripcion_media_2) }
								</div>
								<figure className="col-12 col-md-8 ml-md-auto">
									<img src={this.page.media_2.data.full_url} alt="" className="img-fluid" />
								</figure>
							</div>
							<div className="row layer-twoo">
								<figure className="col-12 col-md-8">
									<img src={this.page.media_3.data.full_url} alt="" className="img-fluid" />
								</figure>
							</div>
							<div className="row layer-three">
								<figure className="col-12 col-md-8 ml-auto">
									<img src={this.page.media_4.data.full_url} alt="" className="img-fluid" />
								</figure>
							</div>
							<div className="row position-relative layer-four">
								<figure className="col-12 col-md-8">
									<img src={this.page.media_5.data.full_url} alt="" className="img-fluid" />
								</figure>
								<div className="col-12 col-md-6 offset-md-5 top-layer-text deeper base-text">
									{ parse(this.page.descripcion_media_5) }
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-4 col-lg-3 ml-md-auto sharing-container">
									<Share />
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-10 px-md-0 mx-auto footer-container">
									<FooterProjectListing type="about_project" project_offset={this.page.sort} />
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			}</div>
		);

	}
}

export default AboutProject;




