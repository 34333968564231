import React from 'react';
import {Helmet} from "react-helmet";
import Menu from "../../menu/Menu";
import { Link } from "react-router-dom";
import Projects from "../../projects-listing/Projects";


class WorkContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isHover: false,
            backgroundImage: "",
            hasLoaded: false
        };

        this.handleHover = this.handleHover.bind(this);
        this.handleLeave = this.handleLeave.bind(this);

        this.page = null;
        this.categories = null; 
        this.categories_html = null; 
    }

    handleHover(event){
        this.setState({
            isHover: true,
            backgroundImage: this.categories[event.currentTarget.getAttribute('data-key')].imagen.data.full_url
        });
    }

    handleLeave(event){
        this.setState({
            isHover: false
        });
    }

    componentWillMount(){
        
        //Verificar el props para ver si esta filtrando algo.

        if( this.props.filtered !== 0 ){

            //Cargar la vista de proyectos

            global.client.getItem("categorias_de_proyectos",
                this.props.filtered,
                
                {
                    fields: ['*','imagen_para_sharing.data']
                }

            ).then(data => {
                
                this.page = data.data;
                this.setState({ hasLoaded: true });
                
            }).catch(
                error => console.error(error) 
            );

        }else{

            //Cargar el indice de Home

            global.client.getItems("pagina_de_work", {
                fields: ['*', 'imagen_para_sharing.data'],
                single: true
            }).then(data => {
                
                this.page = data.data;


                global.client.getItems("categorias_de_proyectos", {
                    fields: ['*', 'imagen.data'],
                    meta: ['result_count'],
                }).then(data => {
                    
                    this.categories = data.data;
                    this.categories_html = data.data.map( (item, key) => 

                        <div key={item.id} className={"col-12 col-md-1 " + (key === 0 ? "offset-md-5" : "") }>
                            <Link to={"/work/" + item.id + "/" + item.nombre}  data-key={key} onTouchStart={this.handleHover} onMouseEnter={this.handleHover} onMouseLeave={this.handleLeave}>{item.nombre}</Link>
                        </div>

                    );

                    this.setState({ hasLoaded: true });

                }).catch(
                    error => console.error(error) 
                );

            }).catch(
                error => console.error(error) 
            );

        }

    }

    componentDidMount() {
        window.onwheel = function(){ return false; }
    }

    render() {
        var isWorkHover = this.state.isHover;
        var isHoverClass = isWorkHover ? "hovered" : "";

        var style = {
            backgroundImage: 'url('+this.state.backgroundImage+')',
            opacity: isWorkHover ? 1 : 0
        }




        return(
            <div className="work page-wrapper has-fullscreen" >
                
                {this.state.hasLoaded &&
                    <div>
                        <Helmet>
                            <title>{ this.page.titulo_para_sharing }</title>
                            <meta name="description" content={ this.page.descripcion_para_sharing }/>

                            <meta property="og:title" content={this.page.titulo_para_sharing} />
                            <meta property="og:description" content={this.page.descripcion_para_sharing} />
                            <meta property="og:image" content={this.page.imagen_para_sharing.data.full_url} />
                        </Helmet>

                        <Menu isActive={true} />

                        

                        {this.props.filtered !== 0 ? (
                            
                            <div>
                                <Projects category={this.page.id} isActive={ true } posy={0} backgroundColor="white" />

                                <div class="work-category">
                                    <h1>{this.page.nombre}</h1>
                                </div>
                            </div>
                            
                        ) : (

                            <div>

                                <div style={style} className="background-work"></div>
                                <div className={ "works-container "+ isHoverClass}>

                                    <div className="container mx-auto  mb-md-0 my-auto">
                                        <div className="row  no-gutters mx-auto  works-nav">
                                            {this.categories_html}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                        )}
                    </div>
                }

                <div className={ "loader black " + (this.state.hasLoaded ? "done" : "") }>
                    <Helmet>
                        <title>Wal</title>
                    </Helmet>

                    <Menu isActive={true} />

                    <div className="loading">
                        <span></span>
                    </div>
            
                </div>

                

            </div>
        );
    }

}

export default WorkContainer;