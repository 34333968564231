import React from 'react';

function CoverProject( props ){

	var width = document.documentElement.clientWidth
    var height = document.documentElement.clientHeight

    var style = {
    	height: height + 'px',
    	backgroundSize: 'cover',
    	backgroundImage: 'url(' + props.source + ')'
    };

	return(
		<section style={style} className='cover' >
			<h1 class="title px-5">{ props.title }</h1>
		</section>
	);
}

export default CoverProject;