import DirectusSDK from "@directus/sdk-js";

global.client = new DirectusSDK();

global.client.login({
  	url: "https://cms.cliqued.it",
  	project: "wal",
  	token: "TWVyaWRhMjAxOQ==",
  	storage: window.localStorage
});
