import React from 'react';


import HomeContainer from "./home-container/HomeContainer";

import './App.css';

function App() {
    return (
        <HomeContainer />
    );
        
}

export default App;
