import React from 'react';

function Share( props ){

	return(
		<div className="w-100 sharing-section">
			<h5 className="title">Sharing</h5>
			<ul className="px-0 sharing-items">
				<li className="sharing-item">
					<a target="_blank" href={"https://www.facebook.com/sharer.php?u="+encodeURI(window.location.href)}>Facebook</a>
				</li>
				<li className="sharing-item">
					<a target="_blank" href={"https://twitter.com/intent/tweet?url="+encodeURI(window.location.href)}>Twitter</a>
				</li>
				<li className="sharing-item d-block d-md-none">
					<a target="_blank" href={"fb-messenger://share/?link="+encodeURI(window.location.href)}>Messenger</a>
				</li>

				<li className="sharing-item">
					<a target="_blank" href={"https://wa.me/?text="+encodeURI(window.location.href)}>WhatsApp</a>
				</li>
			</ul>
		</div>
	);
}

export default Share;