	//Obtiene un color aleatoriamente de los colores de fondo posibles.

var COLORS = ['brown-light','brown-solid','beige-solid','beige-light','rojo','gris','blanco_light','orange','verde_raro','azul_cielo','verde_fuerte','yellow','invertido','gris_2'];

export function getRandBackground( excludedColor ){
	var randomColor;
	do{
		randomColor = COLORS[Math.floor(Math.random()*COLORS.length)];

	}while( randomColor === excludedColor)
	return randomColor;

}