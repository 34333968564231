import React from 'react';

import { Link } from "react-router-dom";
import {convertToSlug} from '../../utils/Slug.js';

class FooterProjectsListing extends React.Component{

	constructor(props) {
        super(props);

        this.state = {
        	hasLoaded: false
        }

        this.handleClick = this.handleClick.bind(this);

        this.projects = null;
        this.projects_html = null;

        this.offset_adjacents = 4; //Traer 2 adyacentes en cada sentido. +1 por el proyecto actual

    }

    componentWillMount(){

    	window.scrollTo(0, 0);
    	
        
        if(this.props.type === "normal_projects" ){
            //Solicitar proyectos normales

            let adjacents = this.props.project_offset - this.offset_adjacents;
            console.log(adjacents);
            let project_query = {
            	fields: ['*', 'imagen_de_portada.data'],
                offset: adjacents >= 0 ? adjacents : 0,
            	limit: '7',
            	
                meta: ['result_count']
            };


            global.client.getItems("proyectos", project_query).then(response => {
                console.log(response.data);
	            this.projects = response.data;
	            this.projects_html = response.data.map( (item, key) => 

	            	
	            	<li key={item.id} data-key={key} onClick={this.handleClick} className={"list-item " + ( item.sort == this.props.project_offset  ? 'active' : '' ) } >
						<div className="thumbnail" style={ { backgroundImage: 'url('+ item.imagen_de_portada.data.full_url +')' } } src={ item.imagen_de_portada.data.full_url }> </div>
						<Link  to={"/project/" + item.id + "/" + convertToSlug(item.nombre)} >
						  {item.nombre}
						</Link>
					</li>

	            );

	    	  	this.setState({
		    		hasLoaded: true
		    	});

        	}).catch(
            	error => console.error(error) 
    		);

        }else{

            let adjacents = this.props.project_offset - this.offset_adjacents;

            let project_query = {
                fields: ['*', 'media_1.data'],
                limit: '7',
                offset: adjacents >= 0 ? adjacents : 0,
                meta: ['result_count']
            };

            global.client.getItems("proyectos_internos", project_query).then(response => {

            
                this.projects = response.data;
                this.projects_html = response.data.map( (item, key) => 
                    
                    <li key={item.id} data-key={key}  className={"list-item " + ( item.sort == this.props.project_offset  ? 'active' : '' ) } >
                        <div className="thumbnail" style={ { backgroundImage: 'url('+ item.media_1.data.full_url +')' } } src={ item.media_1.data.full_url }> </div>
                        <Link  to={"/about/" + item.id + "/" + item.url_amigable} >
                            {item.titulo}
                        </Link>
                    </li>

                );

                this.setState({
                    hasLoaded: true
                });

            }).catch(
                error => console.error(error) 
            );

        }

    }

    handleClick(event){
    	event.currentTarget.classList.add("clicked");
    	//this.props.onClick();
    	localStorage.setItem('clickedProject', this.projects[ event.currentTarget.getAttribute('data-key') ].imagen_de_portada.data.full_url);
    }

    handleHover(event){
    	var image = event.target.parentNode.fisrtChild;
    	console.log(image);
    }

	render(){

		if( this.state.hasLoaded ){

        	return(

				<ul className="footer-project-listing">
					{this.projects_html}
				</ul>
			);

        }else{
        	
        	return(
        		<div className="spinner">Cargando...</div>
        	);

        }
		
	}
}

export default FooterProjectsListing;