import React from 'react';
import {Helmet} from "react-helmet";
import HomeVideo from "../home-video/HomeVideo";
import Menu from "../../menu/Menu";

import _ from 'lodash';

/*
...
*/

class HomeContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { phase:1, hasLoaded: false };

        this.updatePhase = this.updatePhase.bind(this);
        this.updatePhase = _.debounce(this.updatePhase, 100);

        this.page = null; 
    }

    updatePhase( phase ){
        this.setState({ phase: phase});
    }


    componentWillMount(){
        
        global.client.getItems("pagina_de_home", {
            fields: ['*', 'imagen_para_sharing.data'],
            single: true
        }).then(data => {
            
            this.page = data.data;

            
            this.setState({ hasLoaded: true });

        }).catch(
            error => console.error(error) 
        );
    }

    render() {
         
        var phase = this.state.phase;

        
        return(
            <div className="home page-wrapper">
                
                

                {this.state.hasLoaded &&
                    <div>
                        <Helmet>
                            <title>{ this.page.titulo_para_sharing }</title>
                            <meta name="description" content={ this.page.descripcion_para_sharing }/>

                            <meta property="og:title" content={this.page.titulo_para_sharing} />
                            <meta property="og:description" content={this.page.descripcion_para_sharing} />
                            <meta property="og:image" content={this.page.imagen_para_sharing.data.full_url} />
                        </Helmet>

                        <Menu isActive={ phase !== 1 ? true : false} />

                        <HomeVideo
                            source="/video/home.mp4"
                            phase={ phase }
                            onPhaseChange ={ this.updatePhase }
                        />
                    </div>
                }

                <div className={ "loader black " + (this.state.hasLoaded ? "done" : "") }>
                    <Helmet>
                        <title>Wal</title>
                    </Helmet>

                    <div className="loading">
                        <span></span>
                    </div>
            
                </div>
            </div>
        );
        

        
    }

}

export default HomeContainer;