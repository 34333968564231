import React from 'react';
import { Link } from "react-router-dom";

class LinkItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLink: true,
			isHover: false,
			color: '#000000',
		}

		this.handleHover = this.handleHover.bind(this);
		this.handleLeave = this.handleLeave.bind(this);
	}

	componentWillMount(){
		//Verificar tipo de elemento
		if (this.props.item.link === "" || this.props.item.link === null){
			this.setState({
				isLink: false
			});
		}
	}

	handleHover(event){
		this.setState({
			isHover: true,
			color: this.props.item.color,
		});
	}

	handleLeave(event){
		this.setState({
			isHover: false,
		});
	}

	render(){
		return(
			<span>
			{ this.state.isLink ? (
				<a className="project-anchor" style={this.state.isHover ? {color:this.state.color} : {color:"#000000"} }  onMouseEnter={this.handleHover} onMouseLeave={this.handleLeave} href={this.props.item.link} target="_blank">
					{this.props.item.texto}
					<img className="thumbnail" src={this.props.item.imagen_miniatura.data.full_url}/>
				</a>
			) : (
				<span className="project-anchor" style={this.state.isHover ? {color:this.state.color} : {color:"#000000"} } onMouseEnter={this.handleHover} onMouseLeave={this.handleLeave} >
					{this.props.item.texto}
					<img className="thumbnail" src={this.props.item.imagen_miniatura.data.full_url}/>
				</span>
			)}
			</span>
		);

	}

}

export default LinkItem;