import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import App from './app/App';
import Work from './work/Work';

import Team from './team/Team';
import Project from './project/Project';


import About from './about/About';
import Contact from './contact/Contact';

import '../utils/DirectusClient';

function Container({ location }) {
    return (
        <div>
            <TransitionGroup>
                <CSSTransition
                    key={location.pathname}
                    timeout={{ enter: 1500, exit: 1500 }}
                    classNames={'fade'}
                >

                    <Switch location={location} >
                        <Route exact path="/" component={App} />
                        <Route path="/team" component={Team} />
                        
                        <Route path="/project/:id/:name" component={Project} />

                        <Route exact path="/about" component={About} />
                        <Route exact path="/about/:id/:name" component={About} />

                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/contact/:active" component={Contact} />

                        <Route exact path="/work" component={Work} />
                        <Route exact path="/work/:id/:name" component={Work} />

                    </Switch>
                    
                </CSSTransition>
            </TransitionGroup>


        </div>
        
    );
}

export default withRouter(Container);