import React from 'react';

function Media( props ){
	
	if( props.media === null ) return false;
	
	if( props.media.type === "embed/vimeo" ){
		return(
			//<div className="project-video" dangerouslySetInnerHTML={{__html: props.media.data.embed.html }} ></div>
			<div className="embed-responsive embed-responsive-16by9">
				<iframe className="embed-responsive-item" src={"https://player.vimeo.com/video/" + props.media.embed } width="100%" height="auto" frameBorder="0" webkitAllowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
			</div>
			

		);
	}else{
		return(
			<img src={props.media.data.full_url} alt="" className="img-fluid" />
		);
	}

	
}

export default Media;