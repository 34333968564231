import React from 'react';
import _ from 'lodash';
import Projects from "../../projects-listing/Projects";

/*
...
*/

class HomeVideo extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            posz: -20000,
            posy: 0,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
            multiplicador: -1.3
        }

        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);

        this.onWheel = this.onWheel.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);

       //this.onWheel = _.debounce(this.onWheel, 6);
        
        this.updatePosz = _.debounce(this.updatePosz, 7);
        this.updatePosy = _.debounce(this.updatePosy, 2);
    }

    onWheel(event) {
        
        event.persist();

        this.savedEvent = event;
        this.handleScroll( this.savedEvent.deltaY, 1, true);

    }

    onTouchMove(event) {
        var te = event.changedTouches[0].screenY;
        var distance = this.ts - te;
        if (distance >= 230 || distance <= -230){
           this.handleScroll( distance * this.state.multiplicador, 0.5, false);  
        }else {
            this.handleScroll( distance * this.state.multiplicador, 0.5, false);     
        }
        /*
        if (distance >= 230 || distance <= -230){
           this.handleScroll( distance * -1, 0.5, false);  
        }else {
            this.handleScroll( distance * -7, 0.5, false);     
        }*/


    }

    /*onWheel(event) {
        this.handleScroll( event.deltaY, 1);
    }*/

    onTouchStart(event) {
        //console.log(event.changedTouches[0].screenY);
        this.ts = event.changedTouches[0].screenY;
    }

    /*onTouchMove(event) {
        var te = event.changedTouches[0].screenY;
        if (this.ts > te) {
            this.handleScroll( -1, 8); //Abajo
        } else {
            this.handleScroll( 1, 8); //Arriba
        }
    }*/

    handleScroll(direction, modifier, isMouseWheel) {
        
        var scrollDelta = direction;


        if( this.props.phase === 1 ){
            
            var posz = this.getUpdatedZ( scrollDelta, modifier, isMouseWheel );
            this.updatePosz( posz );
            this.state.multiplicador = -1.3;

            //Avanzar a la segunda etapa
            if( posz >= 0 ){
                this.savedEvent = null;
                this.props.onPhaseChange(2);
            }
        

        }else if( this.props.phase === 2){
            this.state.multiplicador = -4;
            var posy = this.getUpdatedY( scrollDelta, modifier, isMouseWheel );
            
            var limit_point = this.state.height * -1;
            this.updatePosy( posy );



            //Regresar a la etapa anterior
            if( posy === 0 ){
                this.savedEvent = null;
                this.props.onPhaseChange(1);
            }

            //Avanzar a la siguiente etapa
            if( posy <= limit_point ){
                
                this.props.onPhaseChange(3);
            }

        }else if( this.props.phase === 3){
            //TODO: Eliminar eventos de algun modo...
            this.setState({posy: this.state.height * -1});
        }

    }

    getUpdatedZ( scrollDelta, modifier, isMouseWheel ){

        var step0 = 2500 * modifier;
        var step1 = 3400 * modifier;

        if( isMouseWheel ){
            step0*= scrollDelta * .009;
            step0 = Math.abs(step0);

            step1*= scrollDelta * .009;
            step1 = Math.abs(step1);

        }else{
            step0*= scrollDelta * .009;
            step0 = Math.abs(step0);

            step1*= scrollDelta * .009;
            step1 = Math.abs(step1);
        }

        var meaddle_point = -1000;
        var limit_point = -20000;

        var posz = this.state.posz;

        if (scrollDelta < 0 && posz < 0) {
            if( posz < meaddle_point ) {
                posz += step1;
            } else {
                posz += step0;
            }
        } else if (scrollDelta > 0 && posz >= limit_point ) {
            if( posz < meaddle_point ) {
                posz -= step1;
            } else {
                posz -= step0;
            }
        }

        return posz;

    }

    getUpdatedY( scrollDelta, modifier, isMouseWheel){

        var step = 120 * modifier;

        if( isMouseWheel ){
            step*= scrollDelta * .009;
            step = Math.abs(step);
        }else{
            step*= scrollDelta * .002;
            step = Math.abs(step);
        }

        var posy = this.state.posy;

        var limit_point = this.state.height * -1;

        if (scrollDelta < 0 && posy >= limit_point ) {
            posy -= step;
        } else if (scrollDelta > 0 && posy < 0 ) {
            posy += step;
        }

        return posy;

    }

    handleResize(event){
        this.setState({
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight
        });
    }

    updatePosz( posz ){
        if( posz > 0 ) posz = 0;
        this.setState({posz: posz});
    }

    updatePosy( posy ){
        if(posy >= 0) posy = 0;
        this.setState({posy: posy});
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        window.onwheel = function(){ return false; }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }


    render() {
        var posz = this.state.posz;
        var posy = this.state.posy;

        var projects_posy = this.state.height + posy;

        var style = {
            transform : 'translate3d(0px, '+posy+'px,'+posz+'px)',
            width: this.state.width+'px',
            height: this.state.height+'px',
        }

        return(
        	
            
            <div className="introduction has-fullscreen" onWheel={ this.onWheel } onTouchStart={this.onTouchStart} onTouchMove={this.onTouchMove} >
                <video playsInline  autoPlay="autoPlay"  muted preload="yes" loop src={this.props.source} style={style}></video>

                <Projects category="0" isActive={ this.props.phase === 3 ? true : false} posy={projects_posy} backgroundColor="beige" />

                <div className={"main-scroll-prompt " + (posy < -100 ? 'd-none' : '') }><span>Scroll/Deslizar</span></div>
            </div>

        );
    }

}

export default HomeVideo;