import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {convertToSlug} from '../../utils/Slug.js';

import { Link } from "react-router-dom";

class ProjectsListing extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isProjectHover: false,
            scrollY: 0,
            offset: 0,
            hasLoaded: false,
            elemets: [],
            index_el: 0,
            scroll: 0,
        }

        this.handleHover = this.handleHover.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.onWheel = this.onWheel.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);

        this.onWheel = _.debounce(this.onWheel, 6);

        this.updateScrolly = _.debounce(this.updateScrolly, 2);

        this.currentActive = null;
        
        this.projects = null;
        this.projects_html = null;
    }

    /*onWheel(event) {
        this.handleScroll( event.deltaY, 1);
    }*/

    onWheel(event) {   

        event.persist();

        this.savedEvent = event;
        this.handleScroll( this.savedEvent.deltaY, 1, true);

    }

    onTouchStart(event) {
        this.ts = event.changedTouches[0].screenY;
    }

    onTouchMove(event) {



        var te = event.changedTouches[0].screenY;
        var distance = this.ts - te;
        console.log(this.ts + "ts  " + te  + "te");
        console.log(distance);
        if (distance >= 230 || distance <= -230){
           this.handleScroll( distance * -1.5, 0.5, false);  
        }else {
                this.handleScroll( distance * -10, 0.5, false);     
            
        }
        
        
    }

    updateScrolly( scrolly ){

        
        var containerHeight = document.getElementById("outer-list-wrapper").clientHeight;
        var innerHeight = document.getElementById("inner-list-wrapper").clientHeight;

        this.setState({scrollY: scrolly, offset: containerHeight - innerHeight});
    }

    handleHover(event){
        
        if(this.currentActive !== null ) this.currentActive.classList.remove("active");
        
        this.currentActive = event.currentTarget;
        this.currentActive.classList.add("active");
        
        this.props.onHover( this.projects[ event.currentTarget.getAttribute('data-key') ].imagen_de_portada.data.full_url );
        this.setState({ isProjectHover: true});
    }

    handleLeave(event){
        this.props.onLeave();
        this.setState({ isProjectHover: false })
    }

    handleClick(event){
        this.props.onClick( this.projects[ event.currentTarget.getAttribute('data-key') ].imagen_de_portada.data.full_url );
    }

    componentWillMount(){


        

        var project_query;
        
        if(this.props.category !== "0" ){
            
            project_query = {
            
                fields: ['*', 'imagen_de_portada.data', 'categorias.categoria.id'],
                meta: ['result_count'],

                '&filter[categorias.categoria.id][in]' : this.props.category

            };

        }else{

            project_query = {

            
                fields: ['*', 'imagen_de_portada.data', 'categoria.id'],
                meta: ['result_count'],

            };

        }

        global.client.getItems("proyectos", project_query).then(response => {
            
            this.projects = response.data;
            this.projects_html = response.data.map( (item, key) => 

                <Link key={item.id} data-key={key} to={"/project/" + item.id + "/" + convertToSlug(item.nombre)} className="project-preview" onClick={this.handleClick} onTouchStart={this.handleHover} onMouseEnter={this.handleHover}  onMouseLeave={this.handleLeave} >
                    <div className="row mx-auto">
                        <div className="col-2 col-md-3 project-number">
                            <p>{key + 1}</p>
                        </div>

                        <div className="col-10 col-md-9 project-name">
                            <p>{item.nombre}</p>
                        </div>
                    </div>
                </Link>

            );

            this.setState({
                hasLoaded: true
            });

        }).catch(
            error => console.error(error) 
        );
    }


    handleScroll( direction, modifier, isMouseWheel ){
        

        var scrollDelta = direction;
        var scrollY;
        var step = 60;
        var offset = 0;

        if( isMouseWheel ){
            var proyectos = document.getElementsByClassName("project-name");
            var proyectos_largos = [];

            for (let i = 0; i < proyectos.length; i++) { 
                if (proyectos[i].textContent.length > 18 && proyectos[i].clientHeight > 65  ) {
                   proyectos_largos.push(i);
                }
            }
           
                if (direction  > 0) {
                    
                    if (proyectos_largos.includes(this.state.index_el-1)) {
                        
                        this.state.index_el -= 1;
                        if (this.state.index_el >= 0 ) {
                            this.state.scroll += 116.667;
                            this.updateScrolly( this.state.scroll  );
                        }else{
                            this.state.index_el = 0;
                        }
                    }else{
                        
                        this.state.index_el -= 1;
                        if (this.state.index_el >= 0  ) {
                            this.state.scroll += 64;
                            this.updateScrolly( this.state.scroll  );
                        }else{
                            this.state.index_el = 0;
                        }
                    }
                    
                }else{

                    if (proyectos_largos.lenght > 2) {
                        offset = proyectos.length - 8;
                    }else{
                        offset = proyectos.length - 6;
                    }

                    if (this.state.index_el <= offset) {
                       if (proyectos_largos.includes(this.state.index_el)) {
                            
                            this.state.index_el += 1;
                            if (this.state.index_el <= proyectos.length+1 ) {
                                this.state.scroll -= 118;
                                this.updateScrolly( this.state.scroll  );
                            }else{
                                this.state.index_el = proyectos.length+1;
                            }
                        }else{
                            
                            this.state.index_el += 1;
                            if (this.state.index_el <= proyectos.length+1 ) {
                                this.state.scroll -= 64;
                                this.updateScrolly( this.state.scroll  );
                            }else{
                                this.state.index_el = proyectos.length+1;
                            } 
                        } 
                    }
                    
                }
        }else{
            step*= direction * .002;
            step = Math.abs(step);

            if (scrollDelta < 0 &&  this.state.scrollY >= this.state.offset ) {
            
            scrollY = this.state.scrollY - (step * modifier);

            this.updateScrolly( scrollY );

            } else if (scrollDelta > 0 && this.state.scrollY <= 0 ) {
                
                scrollY = this.state.scrollY + (step * modifier );

                this.updateScrolly( scrollY );
            }
        }

        

    }

    render(){

        var isProjectHover = this.state.isProjectHover;
        var isHoverClass = isProjectHover ? "hovered" : "";

        var style = {
            transform : 'translate3d(0px, '+this.state.scrollY+'px,0px)',
        }

        if( this.state.hasLoaded ){

            return(

                <div id="outer-list-wrapper" className={ "project-listing "+ isHoverClass} onWheel={ this.props.isActive ? this.onWheel : null } onTouchStart={this.props.isActive ? this.onTouchStart : null} onTouchMove={this.props.isActive ? this.onTouchMove : null}  >
                    <div id="inner-list-wrapper" className="list-wrapper" style={style}>
                        { this.projects_html }
                    </div>
                    
                    
                </div>
            );
        }else{
            return(
                <div className="spinner">
                    <img src="/img/ball.svg" />
                </div>
            );
        }

        
        
    }
}

export default ProjectsListing;